import {GetAllBrokersActionTypes} from './constants';

type Data = {
    
};

type State = {
    data?: Data ;
    loading?: boolean;
    value?: boolean;
};

type GetAllBrokerActionType = {
  type:
      | GetAllBrokersActionTypes.API_RESPONSE_SUCCESS
      | GetAllBrokersActionTypes.API_RESPONSE_ERROR
      | GetAllBrokersActionTypes.GET_ALL_BROKERS_LOADING
      | GetAllBrokersActionTypes.SEARCH_BROKERS
      | GetAllBrokersActionTypes.GET_ALL_BROKERS;
  payload: {
      actionType?: string;
      data?: Data | {};
      error?: string;
  };
};

const emptyData = {
    data: [],
    page: 1,
    page_size: 10
}

const INITIAL_STATE = {
    data: {
        data: emptyData
    },
    loading: false,
    errorMessage: ''
};

const Broker = (state:State = INITIAL_STATE, action: GetAllBrokerActionType) => {
    switch (action.type) {
      case GetAllBrokersActionTypes.API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
            case GetAllBrokersActionTypes.GET_ALL_BROKERS: {
                return {
                    ...state,
                    data: action.payload.data,
                    loading: false,
                    type: action.payload.actionType
                };
            }
            case GetAllBrokersActionTypes.SEARCH_BROKERS: {
                return {
                    ...state,
                    data: action.payload.data,
                    loading: false,
                    type: action.payload.actionType
                };
            }
            default:
                return {
                    ...state, 
                    loading: true,
                    data: {
                        data: emptyData
                    }
                };
        }

      case GetAllBrokersActionTypes.GET_ALL_BROKERS:
        return {
            ...state, 
            data:{
                data: emptyData
            },
            loading: true
        };
      case GetAllBrokersActionTypes.SEARCH_BROKERS:
        return {
            ...state,
            data:{
                data: emptyData
            },
            loading: true
        };
      default:
          return state;
    }
};
export default Broker;