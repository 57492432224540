import { useCallback, useState } from 'react';

export default function useToggle(initialState: boolean = false): [boolean, () => void, () => void, () => void] {
    const [isCanvasOpen, setIsCanvasOpen] = useState(initialState);

    const show = useCallback(() => setIsCanvasOpen(true), []);
    const hide = useCallback(() => setIsCanvasOpen(false), []);
    const toggle = useCallback(() => setIsCanvasOpen(!isCanvasOpen), [isCanvasOpen]);

    return [isCanvasOpen, toggle, show, hide];
}
