import { useState } from 'react';

export default function useDatePicker() {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectEmptyDate, setSelectEmptyDate] = useState<Date>();

    /*
     * handle date change
     */
    const onDateChange = (date: Date) => {
        if (date) {
            setSelectedDate(date);
        }
    };
    const onEmptyDateChange = (date: Date) => {
        if (date) {
            setSelectEmptyDate(date);
        }
    };

    return { selectedDate, onDateChange,selectEmptyDate,onEmptyDateChange };
}
