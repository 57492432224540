import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { getBrokersApi } from 'helpers';
import { getAllBrokerApiResponseSuccess, getAllBrokerApiResponseError } from './actions';
import { GetAllBrokersActionTypes } from './constants';

type PageParams = {
    payload: {
        page ?: number,
        page_size ?: number,
        search ?: string
    };
    type: string;
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getBrokers({payload:{page, page_size}, type}: PageParams): SagaIterator {
    try {
        const response = yield call(getBrokersApi, {page, page_size});
        const data = response.data;
        yield put(getAllBrokerApiResponseSuccess(GetAllBrokersActionTypes.GET_ALL_BROKERS, data));
    } catch (error: any) {
        yield put(getAllBrokerApiResponseError(GetAllBrokersActionTypes.API_RESPONSE_ERROR, error));
    }
}

function* searchBrokers({payload: {search}, type}: PageParams): SagaIterator {
    try {
        const response = yield call(getBrokersApi, {search});
        const data = response.data;
        yield put(getAllBrokerApiResponseSuccess(GetAllBrokersActionTypes.SEARCH_BROKERS, data));
    } catch (error: any) {
        yield put(getAllBrokerApiResponseError(GetAllBrokersActionTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchFetchBrokersSaga(){
    yield takeEvery(GetAllBrokersActionTypes.GET_ALL_BROKERS, getBrokers)
}

export  function* watchSearchBrokersSaga() {
    yield takeEvery(GetAllBrokersActionTypes.SEARCH_BROKERS, searchBrokers)

}

function* brokerSaga() {
    yield all([fork(watchFetchBrokersSaga), fork(watchSearchBrokersSaga)]);
}

export default brokerSaga;